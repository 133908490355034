import { Image, Title, Text } from '@mantine/core';
import partner from '../images/partner.jpg';
import partnerLogo from '../images/partner-logo.svg';
import forest from '../images/forest.svg';
import mountain from '../images/mountain.svg';
import city from '../images/city.svg';
import scale from '../images/scale.svg';
import oldTech from '../images/old-tech.svg';
import approval from '../images/approval.svg';
import land from '../images/land.svg';
import invest from '../images/invest.svg';
import supply from '../images/supply.svg';
import employee from '../images/employee.svg';
import React from 'react';
import './partner.less';

const locations = [
  { key: 'forest', name: 'Forests', icon: forest },
  { key: 'mountain', name: 'Mountains', icon: mountain },
  { key: 'city', name: 'Cities', icon: city },
];

const preconditions = [
  { key: 'approval', title: 'Complicated environmental evaluations and approvals for construction', description: '', icon: approval },
  { key: 'land', title: 'Large amounts of land or area', description: 'all that’s needed per 100,000 tons pulping capacity is a 2000 sq ft (200 sq meter) area for our equipment ', icon: land },
  { key: 'invest', title: 'Large capital investments', description: 'Phase Two Chemicals owns the equipment, you just buy the peroxide', icon: invest },
  { key: 'employee', title: 'No cost for remote control and maintenance', description: 'Phase Two Chemicals handles this', icon: employee },
];

const actions = [
  { key: 'contact', title: 'Contact Us', description: ['info@chengyangtech.com'] },
  { key: 'land', title: 'Identify the installation area', description: [''] },
  { key: 'wait', title: 'Allow 3 months for installation', description: ['Includes module Production, Delivery, Integration, and commissioning'] },
];

function Partner() {
  return (
    <div className="partner">
      <section className="bg">
        <Image className="partner-bg" src={partner} />
        <img className="partner-logo" src={partnerLogo} />
        <div className='text-tag'>Our mission is to become the “Hydrogen Peroxide Manufacturing Partner” of the pulp and paper industry.</div>
      </section>
      <section className="intro">
        <Title className="title">Since our inception, on-site electrochemical hydrogen peroxide production for the pulp and paper industry has been our goal. Our approach has been to design our modular production units to provide the required hydrogen peroxide capacity with reduced cost, easy installation, and green manufacturing practices for any site. Our mission is to become the “Hydrogen Peroxide Manufacturing Partner” of the pulp and paper industry.</Title>
        <Text className="description">Wherever your mills are located – in forests, mountains, or cities, whatever your hydrogen peroxide consumption is, and whether or not you have already have a hydrogen peroxide supplier, our technology offers you the most cost-effective solution.</Text>
      </section>
      <section className="no-matter">
        <div className='location'>
          <Title className='title' order={1}><span><span className='prefix'>Wherever</span>the mill is located</span></Title>
          <div className="content locations">
            {locations.map(({key, name, icon}) => (
              <div key={key} className="item">
                <img src={icon}/>
                <span>{name}</span>
              </div>
            ))}
          </div>
        </div>
        <div className='scale'>
          <Title className='title' order={1}><span><span className='prefix'>Whatever</span>capacity your mill needs</span></Title>
          <div className="content">
            <img src={scale}/>
          </div>
        </div>
        <div className='old-tech'>
          <Title className='title' order={1}><span><span className='prefix'>Whether or not</span>you have existing facilities</span></Title>
          <div className="content">
            <img src={oldTech}/>
          </div>
        </div>
      </section>
      <section className="no-precondition">
        <Title className='title' order={1}>As your hydrogen peroxide manufacturing partner, Phase Two Chemicals does <span>NOT require:</span></Title>
        <div className="list">{preconditions.map(({key, icon, title, description}, index) => (
          <div key={key} className="item">
            <div className="index">{index + 1}</div>
            <img src={icon}/>
            <div className="text">
              <Title className="title" order={2}>{title}</Title>
              {description && (<Text className="description">{description}</Text>)}
            </div>
          </div>
        ))}</div>
      </section>
      <section className="actions">
        <Title className='title' order={1}>Start using our hydrogen peroxide system with three simple steps</Title>
        {/* <Title className='sub-title' order={2}></Title> */}
        <div className="list">{actions.map(({key, title, description}, index) => (
          <div key={key} className="item">
            <div className="index">{index + 1}</div>
            <Title className="title" order={2}>{title}</Title>
            {description.map(item => (<Text className="description">{item}</Text>))}
          </div>
        ))}</div>
      </section>
    </div>
  );
}

export default Partner;
